.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.form-box {
    width: 380px;
    background-color: rgb(22, 163, 71);
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 2%;
}
.header-form {
    margin-bottom: 15px;
    color: white;
}
.btn {
    margin-top: 40px;
    margin-bottom: 10px;
    background-color: blue;
}

.input-group-text {
    color: blue;
    background-color: rgb(22, 163, 71);
    border-style: none;

}
.text-primary {
    color: blue;
}

.login-header {
}

